import { ethers, BigNumber } from 'ethers'
import ABI_WITH_ADDRESS from '../assets/abis/ArtDriveImpl.json'
import { useCallback, useMemo } from 'react'
import { useEthers } from '@usedapp/core'
import { NftWordDataPosition } from '../apis/ServerWalletAPI'
import { CONTRACT_ADDRESS } from '../constants'

export enum ArtDriverType {
  GREY_TYPE = 1,
  BLUE_TYPE = 2,
  ORANGE_TYPE = 3,
}

export const ArtDriverTypeMap: { [key in NftWordDataPosition]: ArtDriverType } =
  {
    verb: ArtDriverType.GREY_TYPE,
    adjective: ArtDriverType.BLUE_TYPE,
    noun: ArtDriverType.ORANGE_TYPE,
  }

export function useWeb3Provider() {
  const { account } = useEthers()
  return useMemo(
    // @ts-expect-error
    () => new ethers.providers.Web3Provider(window.ethereum as any),
    [account]
  )
}

export default function useArtDriverContract() {
  const web3Provider = useWeb3Provider()
  const { account, chainId } = useEthers()
  const contract = useMemo(() => {
    const signer = web3Provider.getSigner()
    return new ethers.Contract(
      CONTRACT_ADDRESS === '' ? ABI_WITH_ADDRESS.address : CONTRACT_ADDRESS,
      ABI_WITH_ADDRESS.abi,
      signer
    )
  }, [web3Provider, account, chainId])

  const tokenURI = useCallback(
    async (tokenId: number): Promise<string> => contract.tokenURI(tokenId),
    [contract]
  )

  const refreshWords = useCallback(
    async (tokenId: number) => {
      try {
        const gasPrice = await web3Provider.getGasPrice()
        const gasLimit = await contract.estimateGas.refreshWords(tokenId, {
          gasPrice,
        })
        const result = await contract.refreshWords(tokenId, {
          gasPrice,
          gasLimit,
        })
        console.log('refresh words succeed, result: ', result)
        return result
      } catch (err) {
        console.error({ err })
        throw err
      }
    },
    [contract, web3Provider]
  )

  const lock = useCallback(
    async (tokenId: number) => {
      const gasPrice = await web3Provider.getGasPrice()
      try {
        const gasLimit = await contract.estimateGas['lock(uint256)'](tokenId, {
          gasPrice,
        })
        // eslint-disable-next-line no-debugger
        debugger
        const result = await contract['lock(uint256)'](tokenId, {
          gasPrice,
          gasLimit,
        })
        console.log(result)
        return result
      } catch (err) {
        console.error({ err })
        throw err
      }
    },
    [contract, web3Provider]
  )

  const addWord = useCallback(
    async (tokenId: number, type: ArtDriverType, word: string) => {
      const gasPrice = await web3Provider.getGasPrice()
      try {
        const gasLimit = await contract.estimateGas.addWord(
          tokenId,
          type,
          word,
          {
            gasPrice,
          }
        )
        const result = await contract.addWord(tokenId, type, word, {
          gasPrice,
          gasLimit,
        })
        console.log('add word succeed, result: ', result)
        return result
      } catch (err) {
        console.error({ err })
        throw err
      }
    },
    [contract, web3Provider]
  )

  const isLocked = useCallback(
    async (tokenId: number): Promise<boolean> => contract.locked(tokenId),
    [contract]
  )

  const getAdded = useCallback(
    async (tokenId: number): Promise<string | null> => contract.added(tokenId),
    [contract]
  )

  const verifyProof = useCallback(
    async (proof: string[], address: string, amount: number) => {
      return contract.verifyProof(proof, address, amount)
    },
    [contract]
  )

  const claimMintAmount = useCallback(
    async (address: string, proof: string[], amount: number) => {
      return contract.claimMintAmount(address, proof, amount)
    },
    [contract]
  )

  const freeMintAmount = useCallback(
    async (address: string) => {
      return contract.freeMintAmount(address)
    },
    [contract]
  )

  const allowedMintAmount = useCallback(
    async (address: string) => {
      return contract.allowedMintAmount(address)
    },
    [contract]
  )

  const mint = useCallback(
    async (
      address: string,
      amount: number,
      totalPrice: BigNumber | string | number
    ) => {
      try {
        const result = await contract.mint(address, amount, {
          gasLimit: 350000 + 200000 * amount,
          value: totalPrice,
        })
        console.log('mint succeed, result: ', result)
        return result
      } catch (err) {
        console.error({ err })
        throw err
      }
    },
    [contract]
  )

  const price = useCallback(async () => {
    try {
      return contract.price()
    } catch (err) {
      console.error({ err })
      throw err
    }
  }, [contract])

  const claimed = useCallback(
    async (address: string) => {
      try {
        return contract.claimed(address)
      } catch (err) {
        console.error({ err })
        throw err
      }
    },
    [contract]
  )

  return {
    contract,
    refreshWords,
    lock,
    addWord,
    isLocked,
    tokenURI,
    getAdded,
    verifyProof,
    claimMintAmount,
    freeMintAmount,
    mint,
    allowedMintAmount,
    price,
    claimed,
  }
}
