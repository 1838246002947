import { ChainID } from '@lay2/pw-core'

function envArg(tag: string, defaultValue: string): string {
  return process.env[tag] ?? defaultValue
}

export const SERVER_URL = envArg('REACT_APP_SERVER_URL', '/')
export const ETH_SERVER_URL = envArg('REACT_APP_ETH_SERVER_URL', '/')
export const WHITELIST_URL = envArg('REACT_APP_WHITELIST_URL', '/')
export const CONTRACT_ADDRESS = envArg('REACT_APP_CONTRACT_ADDRESS', '')
export const CHAIN_ID = Number(envArg('REACT_APP_CHAIN_ID', '1')) || 1
export const CHAIN_URL = envArg(
  'REACT_APP_CHAIN_URL',
  'https://mainnet.infura.io/v3/'
)

// ckb
export const CKB_NODE_URL = envArg(
  'REACT_APP_CKB_NODE_URL',
  'https://testnet.ckb.dev'
)
export const CKB_INDEXER_URL = envArg(
  'REACT_APP_CKB_INDEXER_URL',
  'https://testnet.ckb.dev/indexer'
)
export const CKB_CHAIN_ID = envArg(
  'REACT_APP_CKB_CHAIN_ID',
  ChainID.ckb_testnet.toString()
)
export const IS_MAINNET: boolean =
  CKB_CHAIN_ID !== ChainID.ckb_testnet.toString()
// unipass
export const UNIPASS_URL = envArg(
  'REACT_APP_UNIPASS_URL',
  'https://t.unipass.xyz'
)

export const MINT_START_TIME = envArg('REACT_APP_MINT_START_TIME', '2022-10-30')

export const DAYJS_TIMEZONE = envArg(
  'REACT_APP_DAYJS_TIMEZONE',
  'Asia/Shanghai'
)
