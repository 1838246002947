import serverWalletAPI, { NftData2 } from '../apis/ServerWalletAPI'
import { useEthers } from '@usedapp/core'
import useSWR from 'swr'
import useArtDriverContract from './useArtDriverContract'

export interface NftData extends NftData2 {
  metadata: NftData2['metadata'] & {
    locked: boolean
  }
}

export default function useNft({
  onSuccess,
}: {
  onSuccess: (data: NftData[]) => void
}) {
  const { account } = useEthers()
  const { isLocked, getAdded } = useArtDriverContract()
  return useSWR(
    ['getNftItem', account],
    async () => {
      if (!account) return []
      const items = (await serverWalletAPI.getNftItems(account)) || []
      const metaDataItemsFromContract = await Promise.all(
        items.map(async (item) => ({
          locked: await isLocked(item.token_id),
          added: await getAdded(item.token_id),
        }))
      )
      return items.map((item, i) => ({
        ...item,
        metadata: {
          ...item.metadata,
          ...metaDataItemsFromContract[i],
        },
      }))
    },
    {
      refreshInterval: 3000,
      onError(err) {
        console.log('get nft items error: ', { err })
      },
      onSuccess,
    }
  )
}
