import React, { useMemo } from 'react'
import { QueryClientProvider, QueryClient } from 'react-query'
import { Routers } from './routes'
import { DAppProvider } from '@usedapp/core'
import { CHAIN_ID, CHAIN_URL } from './constants'

const App: React.FC = () => {
  const queryClient = useMemo(() => {
    return new QueryClient()
  }, [])
  return (
    <QueryClientProvider client={queryClient}>
      <DAppProvider
        config={{
          readOnlyChainId: CHAIN_ID,
          readOnlyUrls: {
            [CHAIN_ID]: CHAIN_URL,
          },
        }}
      >
        <Routers />
      </DAppProvider>
    </QueryClientProvider>
  )
}

export default App
