import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import CommonPageTitle from '../../components/CommonPageTitle'
import classnames from 'classnames'
import Slider from 'react-slick'
import useDetectMobile from '../../hooks/useDetectMobile'
import serverWalletAPI, {
  NftWordData,
  NftWordDataPosition,
} from '../../apis/ServerWalletAPI'
import CommonModal, { CommonModalProps } from '../../components/CommonModal'
import './style.scss'
import {
  PushpinOutlined,
  PlusSquareOutlined,
  RedoOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import okImg from '../../assets/img/btn-ok.svg'
import cancelImg from '../../assets/img/btn-cancel.svg'
import twiiterImg from '../../assets/img/twitter.png'
import wechatImg from '../../assets/img/wechat.jpeg'
import wechatPublicAccountImg from '../../assets/img/wechat_public_account.jpeg'
import System from '../../store/system'
import { useEthers } from '@usedapp/core'
import useNft, { NftData } from '../../hooks/useNfts'
import { getImageUrlKey, useImageUrl } from '../../hooks/useImageUrl'
import useArtDriverContract, {
  ArtDriverTypeMap,
} from '../../hooks/useArtDriverContract'
import useSWR, { useSWRConfig } from 'swr'
import { useCurrentNftCard } from '../../hooks/useCurrentNftCard'
import { MintDialog } from '../../components/MintDialog'
import {
  useAirdropReceivingStatus,
  useReceiveAirdrop,
} from '../../hooks/useReceiveAirdrop'
import dayjs from 'dayjs'
import { MINT_START_TIME } from '../../constants'

interface NftImageModalProps extends CommonModalProps {
  data: NftData | null
}

const NftImageModal: React.FC<NftImageModalProps> = ({ data, ...rest }) => {
  const { data: url } = useImageUrl(data)

  return (
    <CommonModal {...rest} className="nft-image-modal">
      <div className="content">
        <div className="desc">{url && <img src={url} alt="" />}</div>
      </div>
    </CommonModal>
  )
}

interface NftComfirmModalProps extends CommonModalProps {
  data: NftData | null
  onOk: () => void | Promise<void>
  text: string | ReactElement
  loading: boolean
}
interface NftAddWordModalProps extends CommonModalProps {
  data: NftData | null
  onOk: (words: NftWordData[]) => void | Promise<void>
  loading: boolean
}

const NftComfirmModal: React.FC<NftComfirmModalProps> = ({
  data,
  onOk,
  text,
  loading,
  ...rest
}) => {
  if (!data) {
    return <></>
  }

  const handleOk = (): void => {
    if (!data) return
    onOk()
  }

  return (
    <CommonModal {...rest} className="nft-fix-modal">
      <div className="content">
        <div className="desc">{text}</div>
        <div className="opts">
          <button disabled={loading} onClick={handleOk}>
            {loading ? <LoadingOutlined /> : <img src={okImg} alt="" />}
          </button>
          <button disabled={loading} className="cancel" onClick={rest.onClose}>
            {loading ? <LoadingOutlined /> : <img src={cancelImg} alt="" />}
          </button>
        </div>
      </div>
    </CommonModal>
  )
}

// const zhReg = /^[\u4e00-\u9fa5]{0,5}$/
const enReg = /^[a-zA-Z]{0,15}$/
function getDefaultWord(): NftWordData {
  return {
    position: 'verb',
    content: {
      en: '',
      zh: '',
    },
  }
}
function getDefaultWordErr(): NftWordErr {
  return {
    en: '',
    zh: '',
  }
}
interface NftWordErr {
  en: string
  zh: string
}
const NftAddWrodModal: React.FC<NftAddWordModalProps> = ({
  data,
  onOk,
  loading,
  ...rest
}) => {
  const [words, setWords] = useState<NftWordData[]>([])
  const [errors, setErrors] = useState<NftWordErr[]>([])

  useEffect(() => {
    if (rest.visible && data) {
      const defaultWords: NftWordData[] = []
      const defaultErrors: NftWordErr[] = []
      // switch (data.class.rarity) {
      //   case 'common':
      //     defaultWords.push(getDefaultWord())
      //     defaultErrors.push(getDefaultWordErr())
      //     break
      //   case 'rare':
      //     defaultWords.push(getDefaultWord())
      //     defaultWords.push(getDefaultWord())
      //     defaultErrors.push(getDefaultWordErr())
      //     defaultErrors.push(getDefaultWordErr())
      //     break
      //   case 'epic':
      //     defaultWords.push(getDefaultWord())
      //     defaultWords.push(getDefaultWord())
      //     defaultWords.push(getDefaultWord())
      //     defaultErrors.push(getDefaultWordErr())
      //     defaultErrors.push(getDefaultWordErr())
      //     defaultErrors.push(getDefaultWordErr())
      // }
      defaultWords.push(getDefaultWord())
      defaultErrors.push(getDefaultWordErr())
      setWords(defaultWords)
      setErrors(defaultErrors)
    }
  }, [data, rest.visible])

  if (!data) {
    return <></>
  }

  const handleChangePosition = (
    index: number,
    value: NftWordDataPosition
  ): void => {
    const word = words[index]
    word.position = value
    setWords([...words])
  }

  const handleChangeEn = (index: number, value: string): void => {
    const word = words[index]
    word.content.en = value
    setWords([...words])
  }

  // const handleChangeZh = (index: number, value: string): void => {
  //   const word = words[index]
  //   word.content.zh = value
  //   setWords([...words])
  // }

  const handleCheck = (): boolean => {
    let result = true
    words.forEach((word, i) => {
      const { en } = word.content
      const err = errors[i]
      err.en = ''
      err.zh = ''
      if (!en) {
        err.en = "Can't be empty"
        result = false
      } else if (!enReg.test(en)) {
        err.en = 'only a-z, A-Z supported'
        result = false
      }
      // if (!zh) {
      //   err.zh = "Can't be empty"
      //   result = false
      // } else if (!zhReg.test(zh)) {
      //   err.zh = 'Only chinese supported'
      //   result = false
      // }
    })
    setErrors([...errors])
    return result
  }

  const handleOk = (): void => {
    if (!data) return
    const result = handleCheck()
    if (!result) return
    onOk(words)
  }

  return (
    <CommonModal
      {...rest}
      title={<CommonPageTitle title="添加词汇" subTitle="Add word" size="3" />}
      className="nft-add-word-modal"
    >
      <div className="content">
        {words.map((word, i) => (
          <div className="new-word" key={i}>
            <div className="radio">
              <div
                className={classnames({ active: word.position === 'verb' })}
                onClick={() => handleChangePosition(i, 'verb')}
              >
                <span>
                  <span>添加排头词（动词）</span>
                  <span>Front Word(verb.)</span>
                </span>
              </div>
              <div
                className={classnames({
                  active: word.position === 'adjective',
                })}
                onClick={() => handleChangePosition(i, 'adjective')}
              >
                <span>
                  <span>添加中间词（形容词）</span>
                  <span>Middle Word(adj.)</span>
                </span>
              </div>
              <div
                className={classnames({ active: word.position === 'noun' })}
                onClick={() => handleChangePosition(i, 'noun')}
              >
                <span>
                  <span>添加结尾词（名词）</span>
                  <span>Last Word(noun.)</span>
                </span>
              </div>
            </div>
            <div className="inputs">
              <div
                className={classnames('input', { error: errors[i].en })}
                data-error={errors[i].en}
                onBlur={handleCheck}
              >
                <span>新词条</span>
                <input
                  type="text"
                  value={word.content.en}
                  onChange={(e) => handleChangeEn(i, e.target.value)}
                />
              </div>
              {/* <div
                className={classnames('input', { error: errors[i].en })}
                data-error={errors[i].en}
                onBlur={handleCheck}
              >
                <span>英文（EN）</span>
                <input
                  type="text"
                  value={word.content.en}
                  onChange={(e) => handleChangeEn(i, e.target.value)}
                />
              </div> */}
            </div>
          </div>
        ))}
        <div className="opts">
          <button disabled={loading} onClick={handleOk}>
            {loading ? <LoadingOutlined /> : <img src={okImg} alt="" />}
          </button>
          <button disabled={loading} className="cancel" onClick={rest.onClose}>
            {loading ? <LoadingOutlined /> : <img src={cancelImg} alt="" />}
          </button>
        </div>
      </div>
    </CommonModal>
  )
}

interface NftCardProps {
  data: NftData
  onSelectCard?: (data: NftData) => void
  size?: 'normal' | 'small'
  active?: boolean
  onShowImage?: (data: NftData) => void
}

const NftCard: React.FC<NftCardProps> = ({
  data,
  onSelectCard,
  size = 'normal',
  active = false,
  onShowImage,
}) => {
  const [loading, setLoading] = useState(false)
  const { data: url } = useImageUrl(data)

  const handleLoad = (): void => {
    setLoading(false)
  }

  return (
    <div
      className={classnames('nft-card', { active })}
      onClick={() => onSelectCard?.(data)}
    >
      <div className="img">
        <img
          onClick={onShowImage ? () => onShowImage(data) : undefined}
          src={url}
          alt=""
          onLoad={handleLoad}
        />
        {loading && <div className="loading">Loading...</div>}
      </div>
      <div className="tid">
        <span>#{data.token_id}</span>
      </div>
    </div>
  )
}

interface CurrentNftCardProps {
  data: NftData | null
  url?: string
  onFix: (data: NftData) => void
  onAddWord: (data: NftData) => void
  onRefresh: (data: NftData) => void
  onShowImage: (data: NftData) => void
  loadingConfig?: {
    text?: string
    isLoading?: boolean
  }
}

const CurrentNftCard: React.FC<CurrentNftCardProps> = ({
  data,
  url,
  onFix,
  onAddWord,
  onRefresh,
  onShowImage,
  loadingConfig,
}) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    const timer = setTimeout(() => {
      setLoading(false)
    }, 5000)
    return () => clearTimeout(timer)
  }, [url])

  const handleLoad = (): void => {
    setLoading(false)
  }

  const isLoading = loading || loadingConfig?.isLoading

  if (!url || !data) {
    return <div className="current-card" />
  }

  return (
    <div className="current-card">
      <div className="nft-card">
        <div className="img">
          <img
            onClick={() => onShowImage(data)}
            src={url}
            alt=""
            onLoad={handleLoad}
          />
          {isLoading ? (
            <div className="loading">{loadingConfig?.text || 'Loading...'}</div>
          ) : null}
        </div>
        <div className="tid">
          <span>#{data.token_id}</span>
          <span>
            {!data.metadata.locked && (
              <>
                <RedoOutlined
                  className={classnames({
                    disabled: isLoading,
                  })}
                  onClick={() => onRefresh(data)}
                />
                <PushpinOutlined
                  className={classnames({
                    disabled: isLoading,
                  })}
                  onClick={() => onFix(data)}
                />
              </>
            )}
            {!data.metadata.added && (
              <PlusSquareOutlined
                className={classnames({
                  disabled: isLoading,
                })}
                onClick={() => onAddWord(data)}
              />
            )}
          </span>
        </div>
      </div>
      <div className="opts">
        {!data.metadata.locked && (
          <>
            <div
              className={classnames({
                disabled: isLoading,
              })}
            >
              <a onClick={() => onRefresh(data)}>
                <RedoOutlined />
                <span>
                  <span>Refresh</span>
                  <span>刷新句子</span>
                </span>
              </a>
            </div>
            <div
              className={classnames({
                disabled: isLoading,
              })}
            >
              <a onClick={() => onFix(data)}>
                <PushpinOutlined />
                <span>
                  <span>Fix the phrase</span>
                  <span>固定句子</span>
                </span>
              </a>
            </div>
          </>
        )}
        {!data.metadata.added && (
          <div
            className={classnames({
              disabled: isLoading,
            })}
          >
            <a onClick={() => onAddWord(data)}>
              <PlusSquareOutlined />
              <span>
                <span>Add words</span>
                <span>添加词汇</span>
              </span>
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

export const Home: React.FC = () => {
  const [fixModalVisible, showFixModal] = useState(false)
  const [addWordModalVisible, showAddWordModal] = useState(false)
  const [refreshModalVisible, showRefreshModal] = useState(false)
  const [imgModalVisible, showImageModal] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [data, setData] = useState<NftData | null>(null)
  const [currentShowData, setCurrentShowData] = useState<NftData | null>(null)
  const { account: address } = useEthers()
  const { alertMessage, showAlertModal } = System.useContainer()
  const [isMobile] = useDetectMobile()
  const [isSetupData, setIsSetupData] = useState(false)
  const { data: nfts = [], mutate: reloadNfts } = useNft({
    onSuccess() {
      setIsSetupData(true)
    },
  })
  const contract = useArtDriverContract()
  const { mutate: reloadSwrByKey } = useSWRConfig()
  const currentNftData = useCurrentNftCard(data)

  const fixedNfts = useMemo(
    () => nfts.filter((nft) => nft.metadata.locked && nft.metadata.added),
    [nfts]
  )
  const notFixedNfts = useMemo(
    () => nfts.filter((nft) => !fixedNfts.includes(nft)),
    [nfts, fixedNfts]
  )

  useEffect(() => {
    if (isSetupData) {
      setIsSetupData(false)
      setData((d) => {
        if (!d) return notFixedNfts[0] || null
        return d
      })
    }
  }, [isSetupData])

  const handleFix = (): void => {
    showFixModal(true)
  }

  const handleAddWord = (): void => {
    showAddWordModal(true)
  }

  const handleRefresh = (): void => {
    showRefreshModal(true)
  }

  const handleImageShow = (data: NftData): void => {
    setCurrentShowData(data)
    showImageModal(true)
  }

  const handleGenError = (error: any): void => {
    const message =
      error.response?.data?.message || error.reason || error.message
    if (message) {
      alertMessage(
        message.split('\n').map((line: string) => <div>{line}</div>),
        {
          okButton: true,
          type: 'error',
        }
      )
    }
  }

  const handleFixOk = async (): Promise<void> => {
    if (!data) return
    setSubmitting(true)
    try {
      await contract.lock(data.token_id)
      showFixModal(false)
      currentNftData.setLoading(
        'Waiting for the transaction to be completed……',
        30000
      )
      await reloadNfts()
    } catch (error) {
      showFixModal(false)
      handleGenError(error)
    } finally {
      setSubmitting(false)
      showFixModal(false)
    }
  }

  const handleRefreshOk = async (): Promise<void> => {
    if (!data || currentNftData.isLoading) return
    setSubmitting(true)
    try {
      await contract.refreshWords(data.token_id)
      showRefreshModal(false)
      currentNftData.setLoading('Refresh succeed! Reloading image……', 30000)
      await reloadNfts()
      await reloadSwrByKey(getImageUrlKey(data.token_id))
    } catch (error: any) {
      setSubmitting(false)
      handleGenError(error)
    } finally {
      setSubmitting(false)
    }
  }

  const handleAddWordOk = async (words: NftWordData[]): Promise<void> => {
    if (!data || currentNftData.isLoading) return
    setSubmitting(true)
    const word = words[0]
    try {
      await contract.addWord(
        data.token_id,
        ArtDriverTypeMap[word.position],
        word.content.en
      )
      showAddWordModal(false)
      currentNftData.setLoading('Add word succeed! Reloading image……', 30000)
      await reloadNfts()
      await reloadSwrByKey(getImageUrlKey(data.token_id))
    } catch (error) {
      showAddWordModal(false)
      handleGenError(error)
    } finally {
      setSubmitting(false)
      showAddWordModal(false)
    }
  }

  const slideSettings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 0,
    slidesToShow: 3,
    autoplay: false,
  }

  const { account } = useEthers()
  const { freeMintAmount, claimed } = useArtDriverContract()
  const { data: whiteList = null } = useSWR(
    ['whitelist', address],
    async () => {
      if (!address) return null
      return await serverWalletAPI.getWhitelist()
    }
  )
  const currentAddressWhiteListItem = useMemo(
    () =>
      whiteList?.find((item) =>
        item[0] && address
          ? item[0].toLowerCase() === address.toLowerCase()
          : null
      ) || null,
    [whiteList, address]
  )
  const onReceiveAirdrop = useReceiveAirdrop(
    whiteList,
    currentAddressWhiteListItem
  )

  const { data: currentFreeMintAmount = 0 } = useSWR(
    [freeMintAmount, account],
    async () => {
      if (!account) return 0
      return await freeMintAmount(account).then(
        (res) => res.toNumber() as number
      )
    },
    {
      refreshInterval: 3000,
    }
  )

  const { data: isClaimed = false } = useSWR([claimed, account], async () => {
    if (!account) return false
    return await claimed(account)
  })

  const isReceiving = useAirdropReceivingStatus()

  const airdropButton = (
    <button
      className="page-title-button"
      onClick={() => {
        onReceiveAirdrop()
      }}
    >
      {isReceiving ? 'Receiving airdrop' : 'Receive airdrop'}
    </button>
  )

  const mintButton = (
    <button
      className="page-title-button"
      onClick={() => {
        const mintStartTime = dayjs.tz(MINT_START_TIME)
        if (
          currentAddressWhiteListItem ||
          mintStartTime.isBefore(dayjs.tz(dayjs()))
        ) {
          alertMessage(<MintDialog />)
        } else {
          alertMessage(
            `Mint start at ${mintStartTime.format(
              mintStartTime.format('YYYY MMM DD HH:mm')
            )}`
          )
          setTimeout(() => {
            showAlertModal(false)
          }, 2000)
        }
      }}
    >
      Mint
    </button>
  )
  return (
    <>
      <div id="home">
        <div className="container">
          <CommonPageTitle
            title="My Driver"
            subTitle="我的驱动器"
            size="1"
            extra={
              <div className="buttons-h-stack">
                {account ? (
                  <a>
                    {currentFreeMintAmount === 0 &&
                    currentAddressWhiteListItem &&
                    !isClaimed &&
                    currentAddressWhiteListItem[1] > 0
                      ? airdropButton
                      : mintButton}
                  </a>
                ) : null}
                <a
                  href="https://mp.weixin.qq.com/s?__biz=MzkzNzI4NzEyMg==&mid=2247484221&idx=1&sn=1eabeff4ad67a56b87804bc9edfea2c3&chksm=c2908f80f5e7069638a9cbc11962d27f0eef0b20c64bba0cf02bd8f639a0eae80e988a740b01&token=603166128&lang=zh_CN#rd"
                  target="_blank"
                >
                  <button className="page-title-button">
                    Operation tutorial
                  </button>
                </a>
              </div>
            }
          />
          {nfts.length === 0 && (
            <div className="empty">
              {address ? 'You have no nft yet' : 'Please connect wallet'}
            </div>
          )}
          {notFixedNfts.length > 0 && (
            <>
              <CommonPageTitle
                title="NFTs ABLE TO OPERATE"
                subTitle="待行权NFT"
                size="2"
              />
              <div className="not-fixed-driver">
                <CurrentNftCard
                  url={currentNftData.url}
                  loadingConfig={{
                    text: currentNftData.loadingText,
                    isLoading: currentNftData.isLoading,
                  }}
                  data={data}
                  onFix={handleFix}
                  onAddWord={handleAddWord}
                  onRefresh={handleRefresh}
                  onShowImage={handleImageShow}
                />
                {isMobile ? (
                  <Slider {...slideSettings} className="cards-slide">
                    {notFixedNfts.map((nft, i) => (
                      <NftCard
                        data={nft}
                        key={i}
                        onSelectCard={setData}
                        size="small"
                      />
                    ))}
                  </Slider>
                ) : (
                  <div className="cards">
                    {notFixedNfts.map((nft, i) => (
                      <NftCard
                        data={nft}
                        key={i}
                        onSelectCard={setData}
                        active={nft === data}
                      />
                    ))}
                  </div>
                )}
              </div>
            </>
          )}
          {fixedNfts.length > 0 && (
            <>
              <CommonPageTitle title="FAVORITES" subTitle="收藏夹" size="2" />
              <div className="fixed-driver">
                {fixedNfts.map((nft, i) => (
                  <NftCard
                    data={nft}
                    key={i}
                    size="small"
                    onShowImage={handleImageShow}
                  />
                ))}
              </div>
            </>
          )}
          <div className="more">
            <div>
              <a
                href="https://digitalcompound.org/#/projects/crypto-art-driver"
                target="_blank"
              >
                <button className="page-title-button">
                  Work interpretation
                </button>
              </a>
            </div>
            <div className="qrcode-stack">
              <img src={twiiterImg} alt="" />
              <img src={wechatImg} alt="" />
              <img src={wechatPublicAccountImg} alt="" />
            </div>
            <div>Contact us</div>
          </div>
        </div>
      </div>
      <NftComfirmModal
        data={data}
        visible={fixModalVisible && !!data}
        onClose={() => showFixModal(false)}
        onOk={handleFixOk}
        text={
          <div>
            <div>固定这个句子？确认后将不可刷新</div>
            <div>
              Fix this Phrase？It would not be refreshed after conformation.
            </div>
          </div>
        }
        title={
          <CommonPageTitle
            title={`固定 #${data ? data.token_id : ''}`}
            subTitle={`Fix #${data ? data.token_id : ''}`}
            size="3"
          />
        }
        loading={submitting}
      />
      <NftComfirmModal
        data={data}
        visible={refreshModalVisible && !!data}
        onClose={() => showRefreshModal(false)}
        onOk={handleRefreshOk}
        text={
          <div>
            <div>确认刷新驱动器？</div>
            <div>Confirm to refresh this Driver？</div>
          </div>
        }
        title={
          <CommonPageTitle
            title={`刷新 #${data ? data.token_id : ''}`}
            subTitle={`Refresh #${data ? data.token_id : ''}`}
            size="3"
          />
        }
        loading={submitting}
      />
      <NftAddWrodModal
        data={data}
        visible={addWordModalVisible && !!data}
        onClose={() => showAddWordModal(false)}
        onOk={handleAddWordOk}
        loading={submitting}
      />
      <NftImageModal
        data={currentShowData}
        visible={imgModalVisible && !!data}
        onClose={() => showImageModal(false)}
      />
    </>
  )
}
