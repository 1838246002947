import { useCallback } from 'react'
import { ethers } from 'ethers'
import { keccak256 } from 'ethereumjs-util'
import { MerkleTree } from 'merkletreejs'
import useArtDriverContract from './useArtDriverContract'
import { useEthers } from '@usedapp/core'
import System from '../store/system'
import { atom, useAtomValue } from 'jotai'
import { useUpdateAtom } from 'jotai/utils'

const AbiCoder = ethers.utils.defaultAbiCoder

const isAirdropReceivingAtom = atom(false)

export function useReceiveAirdrop(
  data: Array<[string, number]> | null,
  item: [string, number] | null
) {
  const { verifyProof, claimMintAmount } = useArtDriverContract()
  const { account: address } = useEthers()
  const { alertMessage, showAlertModal } = System.useContainer()
  const setIsAirdropReceivingAtom = useUpdateAtom(isAirdropReceivingAtom)
  return useCallback(async () => {
    try {
      setIsAirdropReceivingAtom(true)
      if (!address) {
        throw new Error('Please connect the wallet')
      }
      if (!data) {
        throw new Error('Failed to obtain white list')
      }
      if (!item) {
        throw new Error('Not on the white list')
      }
      const amount = item[1]
      const leaves = data.map((x) =>
        ethers.utils.keccak256(AbiCoder.encode(['address', 'uint256'], x))
      )
      const tree = new MerkleTree(leaves, keccak256, { sort: true })
      const leaf = ethers.utils.keccak256(
        AbiCoder.encode(['address', 'uint256'], [address, amount])
      )
      const proof = tree.getHexProof(leaf)
      const verify = await verifyProof(proof, address, amount)
      if (!verify) {
        console.error(verify)
        throw new Error(`Ineligibility: [${proof.join(',')}]`)
      }
      const result = await claimMintAmount(address, proof, amount)
      if (result) {
        alertMessage(<div>Airdrop successfully received!</div>)
      }
    } catch (e: any) {
      alertMessage(<div>{e.message}</div>)
    } finally {
      setIsAirdropReceivingAtom(false)
      setTimeout(() => {
        showAlertModal(false)
      }, 3000)
    }
  }, [
    setIsAirdropReceivingAtom,
    address,
    verifyProof,
    claimMintAmount,
    alertMessage,
    showAlertModal,
    data,
    item,
  ])
}

export function useAirdropReceivingStatus() {
  return useAtomValue(isAirdropReceivingAtom)
}
