import { useEffect, useState } from 'react'
import { useImageUrl } from './useImageUrl'
import { NftData } from './useNfts'

export function useCurrentNftCard(data: NftData | null) {
  const [loadingText, setLoadingText] = useState('')
  const [endTime, setEndTime] = useState<number | undefined>(undefined)
  const [, setPrevUrl] = useState<string | undefined>(undefined)
  const { data: url } = useImageUrl(data, {
    refreshInterval: 3000,
    onSuccess(newUrl) {
      setPrevUrl((pu) => {
        if (pu === newUrl) return pu
        onLoaded()
        return newUrl
      })
      if (endTime && new Date().getTime() > endTime) {
        onLoaded()
      }
    },
  })
  const tokenId = data?.token_id
  const localStorageKey = `TokenStatus:${tokenId || ''}`

  const setLoading = (text: string, timeoutMs: number) => {
    const loadingTextTemp = text
    const endTimeTemp = new Date(new Date().getTime() + timeoutMs).getTime()
    setLoadingText(loadingTextTemp)
    setEndTime(endTimeTemp)
    if (tokenId) {
      localStorage.setItem(
        localStorageKey,
        JSON.stringify({
          loadingText: loadingTextTemp,
          endTime: endTimeTemp,
          prevUrl: url,
        })
      )
    }
  }

  const onLoaded = () => {
    setLoadingText('')
    setEndTime(undefined)
    if (tokenId) {
      localStorage.removeItem(localStorageKey)
    }
  }

  useEffect(() => {
    try {
      const item = localStorage.getItem(localStorageKey)
      if (!item) {
        return
      }
      const status = JSON.parse(item)
      if (status?.loadingText) {
        setLoadingText(status.loadingText)
      }
      if (status?.endTime) {
        setEndTime(status.endTime)
      }
      if (status?.prevUrl) {
        setPrevUrl(status.prevUrl)
      }
    } catch {
      onLoaded()
    }
  }, [data?.token_id, localStorageKey])

  useEffect(() => {
    setPrevUrl((pu) => {
      if (pu === url) return pu
      onLoaded()
      return url
    })
  }, [onLoaded, setPrevUrl, url])

  return {
    loadingText,
    url,
    setLoading,
    isLoading: Boolean(loadingText || endTime),
  }
}
