import './style.css'
import useArtDriverContract from '../../hooks/useArtDriverContract'
import { useEthers } from '@usedapp/core'
import { useState } from 'react'
import System from '../../store/system'
import useSWR from 'swr'
import { BigNumber, utils } from 'ethers'
import { Tooltip } from '@material-ui/core'
import { QuestionCircleOutlined } from '@ant-design/icons'

export const MintDialog: React.FC = () => {
  const { mint } = useArtDriverContract()
  const { account } = useEthers()
  const [amount, setAmount] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const amountNum = Math.abs(parseInt(amount, 10) || 0)
  const { showAlertModal, alertMessage } = System.useContainer()

  const { freeMintAmount, price } = useArtDriverContract()

  const { data: currentPrice = BigNumber.from(0) } = useSWR(
    [price],
    async () => {
      return await price()
    }
  )

  const { data: currentFreeMintAmount = 0 } = useSWR(
    [freeMintAmount],
    async () => {
      if (!account) return 0
      return await freeMintAmount(account)
        .then((res) => res.toNumber() as number)
        .catch(() => 0)
    }
  )

  const isDisabledSubmit = !account || !amountNum
  const currentPriceWithAmount = utils.formatEther(currentPrice.mul(amountNum))

  return (
    <div>
      <h2>Mint new driver</h2>
      <div className="airdrop-container">
        <div>Free Mint Amount: </div>
        <div>{currentFreeMintAmount}</div>
        <div>Amount: </div>
        <div style={{ width: '250px', display: 'flex', lineHeight: '37.5px' }}>
          <input
            type="number"
            placeholder="Please enter amount"
            className="input"
            value={amount}
            min={1}
            max={4}
            onKeyPress={(event) => {
              if (!/[1-4]/.test(event.key)) {
                event.preventDefault()
              }
            }}
            onChange={(e) => {
              setAmount(e.target.value.substring(0, 1))
            }}
            style={{ width: '100%' }}
          />
          <Tooltip
            title={
              <span style={{ fontSize: '16px' }}>
                The security value set to prevent mint failure is below 4
              </span>
            }
            placement="top"
          >
            <div
              style={{
                width: '30px',
                textAlign: 'center',
                cursor: 'pointer',
              }}
            >
              <QuestionCircleOutlined />
            </div>
          </Tooltip>
        </div>
        {currentFreeMintAmount <= 0 ? (
          <>
            <div>Price total: </div>
            <div>
              {currentPriceWithAmount === '0.0' ? 0 : currentPriceWithAmount}{' '}
              Ether
            </div>
          </>
        ) : null}
      </div>
      <button
        className="button full-width"
        style={{ marginTop: '20px', opacity: isDisabledSubmit ? 0.4 : 1 }}
        disabled={isDisabledSubmit}
        onClick={() => {
          if (!account) {
            console.error('no address')
            return
          }
          if (!amountNum) {
            console.error('no amount', amount)
            return
          }
          if (isLoading) return
          setIsLoading(true)
          let totalPrice = currentPrice.mul(amountNum - currentFreeMintAmount)
          if (totalPrice.lt(0)) {
            totalPrice = BigNumber.from(0)
          }
          mint(account, amountNum, totalPrice)
            .then((res) => {
              console.log(res)
              alertMessage(<div>Mint succeed!</div>)
              setTimeout(() => showAlertModal(false), 3000)
            })
            .catch((err) => {
              console.warn(err)
              alertMessage(<div>{err.message}</div>)
              setTimeout(() => showAlertModal(false), 3000)
            })
            .finally(() => {
              setIsLoading(false)
            })
        }}
      >
        Mint
      </button>
      <button
        className="outline button full-width"
        style={{ marginTop: '10px' }}
        onClick={() => showAlertModal(false)}
      >
        Cancel
      </button>
    </div>
  )
}
