import { NftData2 } from '../apis/ServerWalletAPI'
import useArtDriverContract from './useArtDriverContract'
import useSWR from 'swr'
import axios from 'axios'

export function getImageUrlKey(tokenId?: number) {
  return [tokenId, 'getTokenUri'].join(',')
}

export function useImageUrl(
  nft?: NftData2 | null,
  options?: {
    refreshInterval?: number
    onSuccess?: (data?: string) => void
  }
) {
  const { tokenURI } = useArtDriverContract()
  return useSWR(
    getImageUrlKey(nft?.token_id),
    async () => {
      if (!nft) return undefined
      const url = await tokenURI(nft.token_id)
      const response = await axios.get<{ image: string }>(url)
      return response.data.image
    },
    {
      refreshInterval: options?.refreshInterval,
      onSuccess: options?.onSuccess,
    }
  )
}
