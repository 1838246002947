import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

import './index.scss'
import './theme.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { DAYJS_TIMEZONE } from './constants'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault(DAYJS_TIMEZONE)
